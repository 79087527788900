import React from 'react';
import {useFormikContext} from 'formik';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';

export default function DeleteCustomAnswerDialog({showDialog, handle, setShowDialog, setReallyDeleteCustom,question, questionPresets}) {
    const {setFieldValue, submitForm} = useFormikContext();

    const handleConfirmCustomDelete = () => {
        setReallyDeleteCustom(true);
        setShowDialog(false);
        submitForm();
    }

    const handleDenyCustomDelete = () => {
        if (question.answer_type === 'single') {
            setFieldValue('answer', new Array(questionPresets.preset_answers.length));
        }
        setShowDialog(false);
        submitForm();
    }

    return (

        <Dialog open={showDialog} handler={handle} className="rounded-2xl overflow-visible" size="lg"         
        animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 1, y: -100 },
          }}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                Really delete your typed response?
            </DialogHeader>
            <DialogBody divider className="overflow-y-auto max-h-[80vh] mb-7">
                Choosing this option will delete your typed response, did you really want to do that?
            </DialogBody>
            <DialogFooter className="mb-2">
                <div className="absolute bottom-2 left-10">
                    <Button type="button" color="gray" variant="outlined"
                            onClick={() => handleDenyCustomDelete()}>
                        No, keep my response
                    </Button>
                </div>
                <div className="absolute bottom-2 right-10">
                    <Button type="button" color="green"
                            variant="gradient"
                            onClick={() => handleConfirmCustomDelete()}>
                        Yes, change my response
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    )
}
