import {useField} from "formik";
import {Checkbox, Alert} from "@material-tailwind/react";

export default function FormikCheckbox({...props}) {
    const [field, meta] = useField(props);

    return(
        <>
            <Checkbox {...field} {...props}/>
            {(meta.touched && meta.error && 
                <Alert color="red" className="mt-1 rounded-lg py-1 pl-5">{meta.error}</Alert>
            )}
        </>
    )
}
