import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';
import { useFormikContext } from 'formik';

export default function DeleteQuestionDialog({showDialog, deleteIndex, removeCallback, setDeleteArrayState, onClose, handle}) {
    const {values} = useFormikContext();

    const onConfirmDelete = () => {
        const question_id = values.questions?.[deleteIndex]?.id;
        if (question_id) {
            setDeleteArrayState(arr => [...arr, question_id]);
        }
        removeCallback(deleteIndex);
        handle();
        onClose();
    };

    const closeOnly = () => {
        handle();
        onClose();
    }

    return (
        <Dialog open={showDialog} handler={handle} className='min-w-fit'>
            <DialogHeader className='bg-gray-300 text-black font-display rounded-t-lg'>
                <p>Delete Question?</p>
            </DialogHeader>
            <DialogBody divider className='overflow-y-auto max-h-[80vh] mb-7 font-body'>
                {"Are you sure you want to do this? It will permanently remove the selected question from your survey."}
            </DialogBody>
            <DialogFooter className='pt-6'>
                <div className='absolute bottom-4 right-4'>
                    <Button type="button" variant="filled"
                            className='
                            bg-black 
                            rounded-full 
                            text-lg 
                            mr-4  
                            px-6 
                            py-2
                            hover:bg-amber-500
                            hover:text-black
                            font-body 
                            font-thin 
                            normal-case' 
                        onClick={() => closeOnly()}>       
                        Cancel
                    </Button>
                    <Button type="button"
                      className='
                      bg-red-900
                      rounded-full 
                      text-lg 
                      px-6 
                      py-2
                      hover:bg-amber-500
                      hover:text-black
                      font-body 
                      font-thin 
                      normal-case'
                            variant="filled"  
                            onClick={() => onConfirmDelete()}>
                        Delete
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    )
}
