import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import FormikInputIcon from 'components/form/formik-input-icon';
import FormikInput from 'components/form/formik-input';

export default function UpdatePasswordDialog({handlePasswordSubmit, showUpdatePasswordDialog, setShowUpdatePasswordDialog, 
    validationSchemaPassword}) {
    return (
        <Dialog open={showUpdatePasswordDialog} handler={() => setShowUpdatePasswordDialog(showUpdatePasswordDialog=>!showUpdatePasswordDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                Update Password
            </DialogHeader>
            <Formik onSubmit={handlePasswordSubmit} validationSchema={validationSchemaPassword} 
                initialValues={{
                    password: '',
                    confirm_password: '',
                }}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody className='font-body overflow-y-auto max-h-[80vh]'>
                                <div className="mb-6 px-2 bg-bb font-body">
                                    <FormikInputIcon name="password" type="password" color="gray" className='font-body'
                                        label="New Password" iconName="lock" autoComplete="new-password" />
                                </div>
                                <div className="pb-4">
                                    <FormikInput name="confirm_password" type="password" color="gray" className='font-body'
                                        label="Confirm New Password" autoComplete="new-password" />
                                </div>
                        </DialogBody>
                        <DialogFooter>
                        <div className='absolute bottom-4 right-4'>
                                    <Button type="button"  
                                    className='mr-4 hover:bg-red-900 px-6 py-2 hover:text-white hover:border-red-900 border border-gray-500 rounded-full' 
                                    variant="gradient"
                                    color='white'
                                    size="lg" 
                                        onClick={() => setShowUpdatePasswordDialog(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit"  
                                            disabled={!(dirty) || !(isValid)} 
                                            onClick={() => setShowUpdatePasswordDialog(false)}
                                            className="
                                            rounded-full
                                            px-6 py-2 
                                            hover:border-1 hover:border-blue-500 hover:text-blue-500
                                            border
                                            border-gray-500"
                                            variant="gradient"
                                            color="white"
                                            size="lg">
                                        Save
                                    </Button>
                                </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
