import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import { fetcherFull } from 'utils/api';
import FormikInput from '../form/formik-input';
import { toast } from 'react-toastify';

export default function ConfirmReportAdminDialog(props) {
    const handle = () => {
        const showDialogProp = props.showDialog;
        props.setShowConfirmReportDialog(showDialogProp => !showDialogProp);
    }

    const reportSubmit = async (values) => {
        const response = await fetcherFull(
            `/v2/response/question/${props.question.nanoid}/answer/${props.confirmingAnswer.nanoid}/admin_abuse`,
            'POST',
            {admin_comment: values.admin_comment}
        );
        if (response && response?.status === 'reported') {
            toast.success('Answer Reported');
            props.surveyAbuseMutate();
        } else {
            toast.error('Error Reporting Answer');
        }
        handle();
    };

    return (
        <>
            <Dialog open={props.showDialog} handler={handle} className='min-w-fit'>
                <Formik initialValues={{admin_comment: ''}} onSubmit={reportSubmit}>
                    <Form>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Report an Answer
                </DialogHeader>
                <DialogBody className="overflow-y-auto max-h-[80vh] font-body">
                    <Typography className='font-body my-2 text-center'>Add a comment to the reported answer for added context.
                    </Typography>
                            <FormikInput className="p-2 font-body" type="text" variant="static" size="md" 
                            color="indigo" name="admin_comment" placeholder="Enter your comment here!" />
                </DialogBody>
                <DialogFooter className="my-2">
                    <div className="absolute bottom-4 right-4">
                        <Button type="submit"
                                color="white"
                                className="
                                mr-4
                                rounded-full 
                                text-lg 
                                px-6 py-1
                                hover:border-1
                                hover:border-blue-500
                                hover:text-blue-500
                                border
                                border-gray-500
                                font-body normal-case font-thin"
                                variant="filled">
                            Report
                        </Button>
                        <Button type="button" 
                                color='white'
                                className="
                                rounded-full 
                                text-lg 
                                px-6 py-1
                                border
                                border-gray-500
                                hover:border-1
                                hover:border-blue-500
                                hover:text-blue-500
                                font-body normal-case font-thin"
                                variant="filled"
                                onClick={()=>handle()}>
                            Close
                        </Button>
                    </div>
                </DialogFooter>
                    </Form>
                </Formik>
            </Dialog>
        </>
    );
}

