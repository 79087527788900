import {Card, CardHeader, CardBody, CardFooter, Typography, Button} from '@material-tailwind/react';
import {Form, Formik} from 'formik';
import FormikInputIcon from "../../components/form/formik-input-icon";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {reset, userSelector} from "../../store/user";
import {Navigate} from "react-router-dom";		

export default function ResetPassword() {
    const user = useSelector(userSelector)
    const dispatch = useDispatch()        

    const handleSubmit = async (values) => {
        dispatch(reset(values));
    }

    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email Required'),
    });

    if (user.isWaitingForReset) {
        return <Navigate to={'/resetconfirm'}/>
    }

	return (
        <div className={"bg-login-background bg-cover bg-center flex justify-center items-center w-screen h-screen"}>
            <div className={"max-w-sm w-96"}>
                <Card>
                   	<CardHeader color="orange">
	                    <Typography variant="h5" color="white" className="">Reset</Typography>
                    </CardHeader>    		  
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                        {({isValid, dirty}) => (
                        <Form>
                            <CardBody>
					           <Typography variant="h5">Enter the email associated with your Gobby account</Typography>
                               <div className="mb-12 px-4 bg-bb">
                               <FormikInputIcon name="email" type="email" color="light-blue"
                                   label="Email Address" iconName="email" required/>
                                </div>                        
                            </CardBody>
                    <CardFooter>
                    <div className="flex justify-center bg-bb">
                        <Button type="submit" color={isValid && dirty ? "light-blue" : "gray"}
                                variant="outlined" size="lg" 
                                disabled={!(isValid && dirty)}>
                           Next 
                        </Button>
                    </div>
                </CardFooter>
                </Form>                          
                )}
                    </Formik>
				</Card>
			</div>
		</div>
    );
}
