import {useField} from "formik";
import {Alert, Input} from "@material-tailwind/react";
import MaterialIcon from "components/material-icon";

export default function FormikInputIcon({iconName, ...props}) {
    const [field, meta] = useField(props);
    return(
        <>
            <Input {...field} icon={<MaterialIcon name={iconName}/>} {...props} error={meta.touched && meta.error ? true : undefined}/>
            {(meta.touched && meta.error && 
                <Alert color="red" className="mt-1 rounded-lg py-1 pl-5">{meta.error}</Alert>
            )}
        </>
    )
}
