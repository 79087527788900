import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter, IconButton, Typography} from '@material-tailwind/react';
import { FieldArray, Form, Formik } from 'formik';
import MaterialIcon from 'components/material-icon';
import FormikCheckbox from 'components/form/formik-checkbox';

export default function AnswerThemingDialog({showAnswerThemingDialog, setShowAnswerThemingDialog, answerThemes, handleAnswerThemingSubmit,
    themesData, themesPage, nextThemesPage, previousThemesPage, validationSchemaAnswerThemes}) {
    return (
        <Dialog open={showAnswerThemingDialog} handler={() => setShowAnswerThemingDialog(showAnswerThemingDialog=>!showAnswerThemingDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                Theme Answer
            </DialogHeader>
            <Formik initialValues={{
                    themes: answerThemes?.themes || [],
                }} onSubmit={handleAnswerThemingSubmit} validationSchema={validationSchemaAnswerThemes}>
                {({isValid, dirty, values}) => (
                    <Form>
                        <DialogBody className='font-body overflow-y-auto max-h-[80vh]'>
                            <FieldArray name="themes">
                            {({push, remove}) => (
                                <div>
                                    {themesData?.data?.map((theme) => (
                                    <div className="border-b" key={theme.id}>
                                        <div className=" align-bottom">
                                            <FormikCheckbox name="themes" type="checkbox" 
                                                value={theme.id} checked={values.themes.includes(theme.id)}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        push(theme.id);
                                                    } else {
                                                        const idx = values.themes.indexOf(theme.id);
                                                        remove(idx);
                                                    }
                                                }}
                                            />
                                            <span className='align-middle'>{theme.name || 'N/A'}</span>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            )}
                            </FieldArray>
                        </DialogBody>
                        <DialogFooter>
                            <div className='bottom-4 right-4'>
                                <IconButton className="mr-2" color="black" type="button" variant="filled"
                                    disabled={themesPage === 1} onClick={previousThemesPage}>
                                    <MaterialIcon name="arrow_back_ios_new" size="sm"/>
                                </IconButton>
                                <IconButton className="mx-2" color="black" type="button" variant="filled"
                                    disabled={themesData?.pager?.current_page === themesData?.pager?.last_page} 
                                    onClick={nextThemesPage}>
                                    <MaterialIcon name="arrow_forward_ios" size="sm"/>
                                </IconButton>
                                <Button type="button" 
                                    color='white'
                                    className='
                                    mr-4 
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border 
                                    border-gray-700 
                                    px-6 py-2 
                                    rounded-full' 
                                    variant="filled"
                                    size="lg" 
                                    onClick={() => setShowAnswerThemingDialog(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" 
                                    disabled={!(isValid && dirty)} 
                                    onClick={() => setShowAnswerThemingDialog(false)}
                                    color='white'
                                    className='
                                    mr-4 
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border 
                                    border-gray-700 
                                    px-6 py-2 
                                    rounded-full' 
                                    variant="filled"
                                    size="lg">
                                    Save
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}
