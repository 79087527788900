import React,  {useState} from 'react';
import useSWR from 'swr';
import {Form, Formik, useFormikContext} from 'formik';
import FormikRadio from 'components/form/formik-radio';
import FormikCheckbox from 'components/form/formik-checkbox';
import * as Yup from 'yup';
import {fetcherSimple} from 'utils/api';
import DeleteCustomAnswerDialog from './dialog/delete-custom-answer-dialog';

export default function PresetQuestionResponse(props) {
    const question = props.question;
    const responses = props.responses;
    const response = props.responses.preset;

    // Confirmation, if a single answer, we pick preset,
    // and custom already saved
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [reallyDeleteCustom, setReallyDeleteCustom] = useState(false);

    const AutoSubmit = () => {
        const ctx = useFormikContext();
        
        // Validate + Submit on change
        React.useEffect(() => {
            if ((ctx.values.answer[0] || ctx.values.answer[0] === '') 
                && (responses.custom.length > 0 )
                && (responses.preset.length > 0 )
                && (question.answer_type === 'single')) {
                setShowConfirmDialog(true);
            } else if (questionPresets && ctx.dirty && ctx.values && !ctx.isSubmitting) {
                ctx.submitForm();
            }
        }, [ctx.values]);
        return null;
    };

    const {data: questionPresets, error: questionPresetsError} =
          useSWR(() => [
              `/v2/response/question/${question.nanoid}/preset`, 'GET'],
                 ([path, method]) => fetcherSimple(path, method)
    );
    
    // Autosubmitted on value change!
    const handlePresetSubmit = async (values, actions) => {
        // console.log(values);
        if (question.answer_type === 'single') {
            // Radio buttons, pick one, or type
            if (!values.answer[0] || values.answer[0] === '') {
                return null;
            }
            // Confirmed as no: Empty values, reset everything
            if (responses.custom.length > 0 && !reallyDeleteCustom && actions.status && actions.status.confirming) {
                actions.setStatus({confirming: false});
                actions.setValues(new Array(questionPresets.preset_answers.length));
                setShowConfirmDialog(false);
                return;
            }
            // Escape if chose a preset, but single answer and already typed one
            if (responses.custom.length > 0 && !reallyDeleteCustom) {
                actions.setStatus({confirming: true});
                setShowConfirmDialog(true);
                return;
            }
            setReallyDeleteCustom(false);
            actions.setStatus({confirming: false});
            let newAnswers = [];
            // Selected a preset, therefore not allowed to type in the box:
            newAnswers = questionPresets.preset_answers.filter((p) =>
                values.answer[0] === p.nanoid);
            props.setPresetOther(false);
            // If changed (why does useEffect keep resubmitting!?)
            if (newAnswers.length !== response.length
                || (newAnswers[0] && newAnswers[0].nanoid !== response[0].nanoid)) {
                // picked one from the list (remove any custom when single answer):
                props.setAnswers({'preset': newAnswers,
                                  'custom': []});
            }
            actions.setStatus({confirming: false});
            setShowConfirmDialog(false);
        } else {
            // Checkboxes, send all checked ones:
            const newAnswers = questionPresets.preset_answers.filter((p) =>
                values.answer.some((ele) => ele === p.nanoid)
            );
            // If changed (why does useEffect keep resubmitting!?)
            if (newAnswers.length !== response.length) {
                props.setAnswers({
                    'preset': newAnswers,
                    'custom': responses.custom});
            }
        }
    };

    const validationSchemaPreset = 
          Yup.array().of(Yup.string());

    return (
            <>
            {((questionPresetsError) &&
              <div>
                  Error fetching preset answers!
              </div>
             )}
            {(questionPresets && response &&
            <Formik initialValues={{
                        answer: response.length === 0
                            ? new Array(questionPresets.preset_answers.length)
                            : ( question.answer_type === 'single'
                                ? [response[0].nanoid]
                                : questionPresets.preset_answers.map((p) => response.some((ele) => ele.nanoid === p.nanoid) ? p.nanoid : '')
                              )
                    }}
                    onSubmit={handlePresetSubmit}
                    validationSchema={validationSchemaPreset}
                    enableReinitialize
            >
                {({values, isValid, dirty}) => (
                    <Form className='font-body'>
                        {questionPresets.preset_answers.sort((a, b) => {return a.position - b.position;}).map((singlePreset, index) => (
                            <div key={index}>
                                {question.answer_type === 'single' &&
                                <FormikRadio
                                    className='font-body text-blue-gray-700'
                                    id={`values.answer.${index}.nanoid`}
                                    name={`answer.0`}
                                    color="blue"
                                    label={singlePreset.text}
                                    value={singlePreset.nanoid}
                                    labelProps={{
                                        className: "text-blue-gray-700",
                                      }}
                                    type="radio"
                                />
                                }
                                {question.answer_type === 'multiple' &&
                                 <FormikCheckbox
                                    className='font-body text-blue-gray-700'
                                    id={`values.answer.${index}.nanoid`}
                                    name={`answer`}
                                    color="blue"
                                    label={singlePreset.text}
                                    value={singlePreset.nanoid}
                                    type="checkbox"
                                    labelProps={{
                                        className: "text-blue-gray-700",
                                      }}
                                    />
                                }
                            </div>
                        ))}
                        <DeleteCustomAnswerDialog showDialog={showConfirmDialog} setShowDialog={setShowConfirmDialog} 
                        setReallyDeleteCustom={setReallyDeleteCustom} question={question} questionPresets={questionPresets}
                        handler={() => setShowConfirmDialog(showConfirmDialog=>!showConfirmDialog)} />
                        <AutoSubmit />
                    </Form>
                )}
            </Formik>
             )}
            </>
    );
}
