import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { fetcherSimple, fetcherFull } from 'utils/api';
import { Button, IconButton, Tooltip, Typography } from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import { emptyCustomAnswerData } from 'utils/consts';
import CustomResultsDialog from 'components/dialog/custom-results-dialog';
import FormikTextarea from './form/formik-textarea';
import ConfirmReportDialog from './dialog/confirm-report-dialog';


export default function CustomQuestionResponse(props) {
    // The question data
    const question = props.question;
    // All current answers (for setAnswers)
    const responses = props.responses;
    // The current custom answers list
    const customResponses = props.responses.custom;
    const [searchTerm, setSearchTerm] = useState('');
    const [reportedAnswer, setReportedAnswer] = useState(null);
    const [seenMatchPopover, setSeenMatchPopover] = useState(false);
    const [closedMatchTooltip, setClosedMatchTooltip] = useState(false);
    const [openMatchPopover, setOpenMatchPopover] = useState(false);
 
    // dialog
    const [showCustomResultsDialog, setShowCustomResultsDialog] = useState(false);
    const [showConfirmReportDialog, setShowConfirmReportDialog] = useState(false);
    const [searchResults, setSearchResults] = useState({'found':0});


    const {data: customSearchResult, error: customSearchResultError} =
          useSWR(() => [searchTerm.length > 2
                        ? `/v2/response/question/${question.nanoid}/answer?query=${searchTerm}`
                        : null, 'GET'],
                 ([path, method]) => fetcherSimple(path, method));

    useEffect(() => {
        setSearchResults(customSearchResult);
        if (customSearchResult?.found > 0 && seenMatchPopover === false) {
            setOpenMatchPopover(true);
            setSeenMatchPopover(true);
        }
    }, [customSearchResult, seenMatchPopover]);

    useEffect(() => {
        if (customResponses && customResponses.length === 0) {
            setSearchTerm('');
        }
        if ((!customResponses || 
            (customResponses && 
            customResponses.length === 0)) &&
            responses.preset && 
            responses.preset.length === 0) {
                props.setQuestionSaved(false);
        }
    }, [customResponses, responses.preset]);

    const onEditInput = async (event, index) => {
        // Typing/changing box, if adding text and single answer, disable
        // presets
        if (question.answer_type === 'single'
            && event.target.value.length > 0) {
            props.setPresetOther(true);
        }
        // If typed any content, disable the "next" button
        // Not in response = not saved yet, aka a new answer
        // We don't want to do this for edited answers
        if (event.target.value.trim().length === 0 && !customResponses[index]) {
            props.setQuestionSaved(true);
        } else if (event.target.value.length > 0) {
            props.setQuestionSaved(false);
        }
        setSearchTerm(event.target.value.trim());
    };

    const makeEditable = (index) => {
        customResponses[index].saved = false;
        props.update();
        props.setQuestionSaved(false);
    }

    const handleCustomSubmit = async (values) => {
        // insert submission code here
    }

    // Remove an answer from this question (or empty input?)
    const deleteCustomAnswer = async (values, answer, removeAnswer, addNewInput) => {
        if (props.previewModeState) {
            return;
        }

        // Delete from responses (by submitting set_answers without it)
        // If this entry has been saved:
        if (answer.nanoid !== '' && customResponses.find((c) => c.nanoid === answer.nanoid)) {
            // Remove it from the question answers
            await props.setAnswers({'preset': responses.preset,
                                    'custom': responses.custom.filter((c) => c.nanoid !== answer.nanoid)});
            // if its mine, delete it completely!?
            if (answer.is_creator) {
                const result = await fetcherFull(`/v2/response/question/${question.nanoid}/answer/${answer.nanoid}`,
                                                 'DELETE');
            }
            // Remove the entry:
            const index = responses.custom.findIndex((c) => c.nanoid === answer.nanoid);
            // update question_response data:
            props.update();
            // Input to replace it
            // if (question.answer_type === 'multiple' || values.answer.length == 0) {
            //     addNewInput(emptyCustomAnswerData[0]);
            // }
        }
    };

    // post a response to current question (custom)
    const createOrUpdateCustomAnswer = async (values, index, addNewInput, replaceResult, resetForm) => {
        if (props.previewModeState) {
            return;
        }
        if (customResponses[index]?.text === values.answer[index]?.text) {
            customResponses[index].saved = true;
            props.update();
            resetForm({
                values : {answer: customResponses.length === 0
                    ? emptyCustomAnswerData
                    : ( question.answer_type === 'single'
                        ? customResponses
                        : [ ...customResponses, ...emptyCustomAnswerData]
                        )}
            })
            if (values.answer[index]?.text) {
                props.setQuestionSaved(true);
            }
            return;
        }

        // 'Tick' button, submit current answer to this question
        // Update if changed? (dont create new!)
        let result;
        let isEdit = false;
        if (values.answer[index].nanoid !== '') {
            isEdit = true;
            result = await fetcherFull(`/v2/response/question/${question.nanoid}/answer/${values.answer[index].nanoid}`,
                                       'PUT', {'text': values.answer[index].text});
        } else {
            // current custom answer:
            result = await fetcherFull(`/v2/response/question/${question.nanoid}/answer`, 'POST', {'text': values.answer[index].text});
        }
        if (result.nanoid) {
            // update question_response data:
            props.update(result);
            // replace array item:
            replaceResult(index, result);
            // allow more responses if multiple
            if (question.answer_type === 'multiple' && !isEdit) {
                addNewInput(emptyCustomAnswerData[0]);
            }
            setSearchTerm('');
            // now we can visit the next question
            props.setQuestionSaved(true);
        }
        // if (current === survey.questions.length) {
        //     // save questionResponse for entire survey
        // }
    };

    const addCustomAnswer = async (values) => {
        // array matching len of search results, nanoids if chosen,
        // else empty string
        if (props.previewModeState) {
            return;
        }
        if (question.answer_type === 'single') {
            // Radio buttons, pick one
            if (!values.answer[0] || values.answer[0] === '') {
                return null;
            }
            // There can be only one!
            let newAnswer = [];
            newAnswer = searchResults.hits.filter((p) =>
                values.answer[0] === p.document.answer_nanoid)
                .map((a) => ({'nanoid':a.document.answer_nanoid}));
            props.setAnswers({'preset': [],
                              'custom': newAnswer});
        } else {
            // Checkboxes, send all checked ones, plus any we wrote ourselves!:
            const newAnswers = searchResults.hits.filter((p) =>
                values.answer.some((ele) => ele === p.document.answer_nanoid)
            ).map((a) => ({'nanoid':a.document.answer_nanoid}));
            newAnswers.push(...responses.custom);
            // filter for unique:
            function onlyUnique(value, index, self) {
                return self.findIndex((ele) => ele.nanoid === value.nanoid) === index;
            }
            newAnswers.filter(onlyUnique);
            props.setAnswers({
                'preset': responses.preset,
                'custom': newAnswers,
            });
        }
        setSearchTerm('');
        props.update();
    };
    

    const CustomString = Yup.object().shape({
        custom: Yup.array().of(Yup.string().min(1, 'Answer is Empty!'))
    });

    const validationSchemaCustom = Yup.object().shape({
        custom: Yup.array().of(CustomString)
    });

    return (
        <>
            {((customSearchResultError) &&
              <div className='font-body'>
                  Error getting search results!
              </div>
             )}
            {( question && customResponses && 
               <Formik initialValues={{
                            answer: customResponses.length === 0
                                ? emptyCustomAnswerData
                                : ( question.answer_type === 'single'
                                    ? customResponses
                                    : [ ...customResponses, ...emptyCustomAnswerData]
                                    )
                       }}
                       onSubmit={handleCustomSubmit}
                       validationSchema={validationSchemaCustom}
                       enableReinitialize
               >
                {({values, isValid, dirty, resetForm}) => (
                    <Form className='font-body text-black sm:mt-4 mt-2'>
                        {question.answer_type === 'single'
                         ? 'Your response'
                         : 'Your response(s)'}
                        <FieldArray name="answer">
                            {({push, remove, replace, insert}) => (
                                <>
                                    {values.answer.map((singleAnswer, index) => (
                                        <div key={index}>
                                            <div className="flex md:flex-nowrap font-body pb-2">
                                            {(question.is_view_all && !singleAnswer.saved &&
                                                <div className="flex grid row border-r border-gray-400 mr-2 pr-2">
                                                    <div className='text-body font-bold text-blue-gray-700'>Matches:</div>
                                                    <div>
                                                        <Tooltip className="border rounded-xl border-blue-gray-500 bg-blue-500 px-4 py-2 shadow-xl shadow-black/10"
                                                            open={openMatchPopover}
                                                            handler={setOpenMatchPopover}
                                                            content={
                                                                <div className="flex flex-row">
                                                                    <div>
                                                                        <Typography color="white" as="span" align="center">
                                                                            You have matched responses.<br />Click onthe number to view.
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            }>
                                                            <Button type="button"
                                                                variant="filled"
                                                                className='md:w-full rounded-xl border-2 border-gray-600 
                                                                    text-lg sm:px-2 text-gray-900 font-body py-2'
                                                                color={customSearchResult && customSearchResult.found > 0 ? "orange" : "white"}
                                                                disabled={!customSearchResult || customSearchResult.found === 0}
                                                                onClick={(() => setShowCustomResultsDialog(true))}>
                                                                {customSearchResult ? customSearchResult.found : 0}
                                                            </Button>
                                                        </Tooltip>
                                                    </div>                 
                                                </div>
                                                )}
                                                <div className='grow md:mr-2'>
                                                <FormikTextarea
                                                    autoComplete="off"
                                                    color="blue"
                                                    placeholder="Add a response"
                                                    shrink={true}
                                                    variant="static"
                                                    rows="1"
                                                    name={`answer.${index}.text`}
                                                    maxLength={question.answer_limit || 150}
                                                    className="min-h-0 focus:border-0 font-body pb-1"
                                                    disabled={singleAnswer.saved}
                                                    onKeyUp={(event) => onEditInput(event, index)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex mt-0 border-b border-gray-400 mb-2 pb-1">
                                                    <div className='grow text-platinumheavy md:font-display font-body text-xs md:text-sm'>
                                                        {values?.answer?.[index].text.length} / {question.answer_limit || 150} characters
                                                    </div>
                                                    <div className=''>
                                                        {( singleAnswer.is_creator &&
                                                        <IconButton
                                                            color={singleAnswer.saved ? 'blue' : 'gray'}
                                                            type="button"
                                                            variant="gradient"
                                                            disabled={(!isValid && !singleAnswer.saved)}
                                                            onClick={() => {makeEditable(index, values)}}>
                                                            <MaterialIcon name="edit" size="lg"/>
                                                        </IconButton>
                                                        )}
                                                        {(singleAnswer.saved &&
                                                        <IconButton
                                                            color="red"
                                                            type="button"
                                                            variant="gradient"
                                                            className='ml-1'
                                                            disabled={!isValid}
                                                            onClick={() => deleteCustomAnswer(values, singleAnswer, remove, push)}>
                                                            <MaterialIcon name="close" size="lg"/>
                                                        </IconButton>
                                                        )}
                                                        {((isValid && !singleAnswer.saved) &&
                                                        <IconButton
                                                            color={isValid && !singleAnswer.saved ? 'green' : 'gray'}
                                                            type="button"
                                                            variant="gradient"
                                                            className='ml-1'
                                                            disabled={!isValid || singleAnswer.saved || !(values?.answer?.[index].text.trim().length)}
                                                            onClick={() => createOrUpdateCustomAnswer(values, index, push, replace, resetForm)}>
                                                            <MaterialIcon name="check" size="lg"/>
                                                        </IconButton>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                    </Form>
                )} 
            </Formik>
             )}
            <CustomResultsDialog
                showDialog={showCustomResultsDialog}
                setShowConfirmReportDialog={setShowConfirmReportDialog}
                handle={() => setShowCustomResultsDialog(showCustomResultsDialog=>!showCustomResultsDialog)}
                setReportedAnswer={setReportedAnswer}
                searchResults={searchResults}
                searchTerm={searchTerm}
                question={question}
                responses={responses}
                saveSelection={addCustomAnswer}
                close={() => setShowCustomResultsDialog(showCustomResultsDialog=>!showCustomResultsDialog)}
            />
            <ConfirmReportDialog
                showDialog={showConfirmReportDialog}
                showCustomResultsDialog={showCustomResultsDialog}
                question={question}
                reportedAnswer={reportedAnswer}
                setShowConfirmReportDialog={setShowConfirmReportDialog}
                setShowCustomResultsDialog={setShowCustomResultsDialog}
            />
        </>
    );
}
