import {Route, Routes} from 'react-router-dom';
import 'material-icons/iconfont/material-icons.css';
import './assets/styles/index.css';
import Login from './pages/user/login';
import AdminWrapper from "./admin-wrapper";
import BuildSurvey from './pages/survey/build';
import ListSurvey from './pages/survey/list';
import LandingSurvey from './pages/survey/landing';
import RespondSurvey from './pages/survey/respond';
import ResetPassword from './pages/user/resetpassword';
import ResetConfirm from './pages/user/resetconfirm';
import Dashboard from "./pages/dashboard";
import Profile from "./pages/user/profile";
import Logout from "./pages/user/logout";
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                transition={Flip}
                pauseOnHover
                theme="light"
                />
            <Routes>
                <Route path={"/login"} element={<Login/>}/>
                <Route path={"/resetpassword"} element={<ResetPassword/>} />
                <Route path={"/resetconfirm"} element={<ResetConfirm/>} />
                <Route path={"/logout"} element={<Logout/>}/>
                <Route path={"/respond/:surveyNanoid"} element={<LandingSurvey/>}/>
                <Route path={"/respond/:surveyNanoid/answer"} element={<RespondSurvey/>}/>
                <Route path={"/"} element={<AdminWrapper/>}>
                    <Route index element={<Dashboard/>}/>
                    <Route path={"profile"} element={<Profile/>}/>
                    <Route path={"survey"}>
                        <Route index element={<ListSurvey/>}/>
                        <Route path={":surveyId"} element={<BuildSurvey/>}/>
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default App;
