import {logout} from "../../store/auth";
import {useDispatch} from "react-redux";
import {Navigate} from "react-router-dom";

export default function Logout() {
    const dispatch = useDispatch();

    dispatch(logout());

    return <Navigate to={"/login"}/>;
}
