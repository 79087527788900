import React from "react";
import {
    Typography
} from '@material-tailwind/react';

// TODO: Build here a routing based on the status of the survey
// It should route you to the landing page if the survey is already complete
export function ResponseWrapper(props) {
    return (
        <div className={"min-w-screen min-h-screen bg-gray-200 justify-center flex flex-col"}>
            <div className={"flex justify-centre grow"}>
                <div className="w-full ">
                    {props.children}
                </div>
            </div>
            <div className="h-28 w-full bg-[url('assets/img/backgrounds/footer_black.png')] flex flex-row">
                <div className="w-0 lg:w-[450px] sm:bg-[url('assets/img/backgrounds/for_real.png')]">
                </div>
                <div className="md:pl-2 sm:pt-1 md:grow sm:mt-16">
                    <div className='flex flex-row text-center pt-2'>
                        <Typography className="font-body text-sm grow px-4">
                                    <a href="https://gobby.io/"
                                    target="_blank"
                                    className='text-blue-400 visited:text-white hover:text-orangesoda active:text-merlingreen' rel="noreferrer">
                                        Gobby Homepage
                                    </a>
                        </Typography>
                        <Typography className="font-body text-sm grow">
                                <a href="https://gobby.io/privacy-policy/"
                                    target="_blank"
                                    className='text-blue-400 visited:text-blue-gray-700 hover:text-orangesoda active:text-merlingreen' rel="noreferrer">
                                        Privacy Policy
                                    </a>
                        </Typography>
                        <Typography className="font-body text-sm grow px-4">
                                <a href="https://gobby.io/terms-and-conditions/"
                                    target="_blank"
                                    className='text-blue-400 visited:text-blue-gray-700 hover:text-orangesoda active:text-merlingreen' rel="noreferrer">
                                        Terms and Conditions
                                    </a>
                        </Typography>
                        <Typography className="font-body text-sm grow">
                                <a href="https://www.shadowcat.co.uk/"
                                    target="_blank"
                                    className='text-blue-400 visited:text-blue-gray-700 hover:text-orangesoda active:text-merlingreen' rel="noreferrer">
                                        Shadowcat Homepage
                                    </a>
                        </Typography>
                    </div>
                </div>       
            </div>
        </div>
    );
}
