import React from 'react';
import {Button, Dialog, DialogHeader, DialogBody, DialogFooter} from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import FormikInputIcon from 'components/form/formik-input-icon';
import Image from 'components/image';

export default function UpdateDetailsDialog({userData, handleUserSubmit, showUpdateDetailsDialog, setShowUpdateDetailsDialog, 
    validationSchemaUser, handleDeleteProfilePic, profilePicUploadHandler, inputLogoFileRef, timestamp, imageUrl, UploadPicture,
    setProfilePicDeletable, handleLogoUploadClick, profilePicDeletable}) {
    return (
        <Dialog open={showUpdateDetailsDialog} handler={() => setShowUpdateDetailsDialog(showUpdateDetailsDialog=>!showUpdateDetailsDialog)}>
            <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                Update Password
            </DialogHeader>
            <>
            <Formik onSubmit={handleUserSubmit} validationSchema={validationSchemaUser} enableReinitialize 
                initialValues={{
                    name: userData?.name || '',
                    alt_email: userData?.alt_email || '',
                    phone: userData?.phone || '',
                    address_1: userData?.address_1 || '',
                    address_2: userData?.address_2 || '',
                    postcode: userData?.postcode || '',
                    country: userData?.country || '',
                    organisation: userData?.organisation || '',
                    job_role: userData?.job_role || ''
                }}>
                {({isValid, dirty}) => (
                    <Form>
                        <DialogBody className='font-body overflow-y-auto max-h-[80vh]'>
                            <div className='font-body text-gray-800 mt-4'>
                                <div className="mt-4 md:flex md:flex-row md:items-top bg-white
                                            md:border border-gray-700 rounded-xl">
                                    <div className='md:p-4 p-2'>
                                        <Button variant="gradient" type="button" color="white"
                                            onClick={() => handleLogoUploadClick()}
                                            className='rounded-lg px-2 py-2 mx-auto 
                                            mt-1 border border-gray-500
                                            hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                            font-body normal-case font-thin' size="md">
                                        Upload Image
                                        </Button>
                                        {( profilePicDeletable &&
                                        <Button variant="gradient" type="button"
                                            onClick={() => handleDeleteProfilePic()}
                                            color="white"
                                            className='rounded-full px-6 py-2 mr-2 
                                            mt-1 border border-gray-500
                                            hover:border-1 hover:border-blue-500 hover:text-blue-500 
                                            font-body normal-case font-thin' size="lg">
                                            Delete Uploaded Logo
                                        </Button>
                                        )}
                                        <input onChange={profilePicUploadHandler}
                                            ref={inputLogoFileRef}
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                        />
                                        <br/><br/><br/><strong>Currently WIP API, <br />not working/hooked up</strong>
                                    </div>
                                    <div className='p-2'>
                                        <Image alt="Profile picture" 
                                            key={timestamp}
                                            src={imageUrl}
                                            className="rounded-xl"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src=`${UploadPicture}`;
                                                setProfilePicDeletable(false);
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>
                                <div className="mb-6 px-2 bg-bb font-body">
                                    <FormikInputIcon name="name" type="password" color="gray" className='font-body'
                                        label="Display Name" iconName="person" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="alt_email" type="email" color="gray" className='font-body bg-white'
                                        label="Alternative Email Address" iconName="alternate_email" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="phone" type="text" color="gray" className='font-body bg-white'
                                        label="Contact Phone Number" iconName="call" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="address_1" type="text" color="gray" className='font-body bg-white'
                                        label="First Line of Address" iconName="contact_mail" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="address_2" type="text" color="gray" className='font-body bg-white'
                                        label="Second Line of Address" iconName="contact_mail" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="postcode" type="text" color="gray" className='font-body bg-white'
                                        label="Postcode/Zip Code" iconName="contact_mail" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="country" type="text" color="gray" className='font-body bg-white'
                                        label="Country" iconName="contact_mail" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="organisation" type="text" color="gray" className='font-body bg-white'
                                        label="Organisation/Company Name" iconName="store" autoComplete="off" />
                                </div>
                                <div className="pb-4">
                                    <FormikInputIcon name="job_role" type="text" color="gray" className='font-body bg-white'
                                        label="Role in Organisation" iconName="badge" autoComplete="off" />
                                </div>
                        </DialogBody>
                        <DialogFooter>
                        <div className='absolute bottom-4 right-4'>
                                    <Button type="button"  
                                    className='mr-4 hover:bg-red-900 px-6 py-2 hover:text-white hover:border-red-900 border border-gray-500 rounded-full' 
                                    variant="gradient"
                                    color='white'
                                    size="lg" 
                                        onClick={() => setShowUpdateDetailsDialog(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit"  
                                            disabled={!(dirty) || !(isValid)} 
                                            onClick={() => setShowUpdateDetailsDialog(false)}
                                            className="
                                            rounded-full
                                            px-6 py-2 
                                            hover:border-1 hover:border-blue-500 hover:text-blue-500
                                            border
                                            border-gray-500"
                                            variant="gradient"
                                            color="white"
                                            size="lg">
                                        Save
                                    </Button>
                                </div>
                        </DialogFooter>
                    </Form>
                )}
            </Formik>
            </>
        </Dialog>
    )
}
