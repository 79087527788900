import {Card, CardHeader, CardFooter, Typography} from '@material-tailwind/react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {reset, userSelector} from "../../store/user";
		

export default function ResetPassword() {
    const user = useSelector(userSelector)
    const dispatch = useDispatch()        

    const handleSubmit = async (values) => {
        dispatch(reset(values));
    }

    const initialValues = {
        email: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email Required'),
    });

	return (
        <div className={"bg-login-background bg-cover bg-center flex justify-center items-center w-screen h-screen"}>
            <div className={"max-w-sm w-96"}>
                <Card>
                   	<CardHeader color="orange">
                        <Typography variant="h5" color="white">Authentication Required</Typography>
                    </CardHeader>    		  
                    <CardFooter>
                    <div className="flex justify-center bg-bb">
                        <Typography>
                        For your security, we need to authenticate your requests.<br/>
                        We've sent a link to your email<br/>
                        Please click on it to complete the reset of your password.
                        </Typography>
                    </div>
                </CardFooter>
				</Card>
			</div>
		</div>
    );
}
