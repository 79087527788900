import useSWR from 'swr';
import dayjs from 'dayjs';
import { fetcherSimple } from 'utils/api';
import ContentWrapper from 'components/ui/content-wrapper';
import {Card, CardHeader, CardBody, CardFooter, 
    Button, Typography} from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import profilepic from 'assets/img/uploadprofilepic.png';
import Image from 'components/image';
import { useState } from 'react';

export default function Dashboard() {
    //AccordionControls
    // const [open, setOpen] = useState(0);     
    // const handleOpen = (value) => setOpen(open === value ? 0 : value);

    // timestamp value function with dayjs
    const [timestamp, setTimestamp] = useState(dayjs());

    const {
        data: userData,
        error: UserDataError,
        mutate: userDataMutate,
        isValidating: userDataIsValidating,
    } = useSWR([`/v2/user`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const pfpImageUrl = process.env.REACT_APP_API_SERVER + `/v2/user/picture`;

    return (
        <>
            <ContentWrapper>
                <div className='pt-6 py-2 mb-6'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:px-6 px-2 rounded-xl md:rounded-3xl 
                bg-gray-100 border-b-2 lg:border-b-2 border-blue-gray-500 w-fit mx-auto'>
                    Dashboard
                </h1>
                </div>
                <div className="mb-10 p-2 text-start rounded-xl bg-gray-200 lg:p-6 p-4">
                    <Typography variant="h6">
                        Welcome back, {userData?.name || ''}!
                    </Typography>
                    <div className='flex flex-row gap-2'>
                        <div className='basis-10/12 min-h-[100px] mt-4 px-2'>
                            <div className='grid grid-cols-2 gap-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>General Info</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left">
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Total Surveys
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Total Respondents
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Surveys Live
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Respondents Online
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Open Moderations (all surveys)
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Number of Themes (all Surveys)
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        #NUM
                                                    </Typography>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Surveys by Status</Typography>
                                    </CardHeader>
                                    <CardBody>
                                    <table className="w-full table-auto text-left border-b border-blue-gray-500">
                                        <thead>
                                            <tr>
                                                <th className="border-b border-blue-gray-500 bg-blue-gray-200 px-2 py-2 rounded-t-lg">
                                                    <Typography variant="small" color="blue-gray" className="font-normal leading-none">
                                                        Headings (take from row text below)
                                                    </Typography>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Survey Type
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                        Respondents
                                                    </Typography>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>

                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Active Surveys</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left border-b border-blue-gray-500">
                                            <thead>
                                            <tr>
                                                <th className="border-b border-blue-gray-500 bg-blue-gray-200 px-2 py-2 rounded-t-lg">
                                                    <Typography variant="small" color="blue-gray" className="font-normal leading-none">
                                                    Headings (take from row text below)
                                                    </Typography>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                    Survey Name
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <Typography className="font-body text-sm">
                                                    # Respondents
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <Typography className="font-body text-sm">
                                                    # Moderation Requests
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <Typography className="font-body text-sm">
                                                    Themes
                                                    </Typography>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Draft Surveys</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left border-b border-blue-gray-500">
                                            <thead>
                                                <tr>
                                                    <th className="border-b border-blue-gray-100 bg-blue-gray-200 px-2 py-2 rounded-t-lg">
                                                        <Typography variant="small" color="blue-gray" className="font-normal leading-none">
                                                        Headings (take from row text below)
                                                        </Typography>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                <td className="px-2 py-1" color="blue-gray">
                                                    <Typography className="font-body text-sm">
                                                    Survey Name
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <Typography className="font-body text-sm">
                                                    # of Themes
                                                    </Typography>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <Typography className="font-body text-sm">
                                                    Date Created
                                                    </Typography>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='mt-4'>
                                <Card className='bg-white min-h-[100px] rounded-xl'>
                                    <CardHeader floated={false} className='w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-lg font-display'>
                                        <Typography>Account Status</Typography>
                                    </CardHeader>
                                    <CardBody>
                                        <table className="w-full min-w-max table-auto text-left">
                                            <tbody>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1" color="blue-gray">
                                                        <Typography className="font-body text-sm">
                                                        Account Type
                                                        </Typography>
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                        [DETAILS]
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1" color="blue-gray">
                                                        <Typography className="font-body text-sm">
                                                        Number of Users
                                                        </Typography>
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                        [Used] of [Total allowed]
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1" color="blue-gray">
                                                        <Typography className="font-body text-sm">
                                                        Plan Length
                                                        </Typography>
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                        #NUM
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1" color="blue-gray">
                                                        <Typography className="font-body text-sm">
                                                        Renewal Date
                                                        </Typography>
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                        [DETAILS]
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="even:bg-blue-gray-50/50">
                                                    <td className="px-2 py-1" color="blue-gray">
                                                    </td>
                                                    <td className="px-2 py-1">
                                                        <Typography className="font-body text-sm">
                                                            <Button
                                                                type="button"
                                                                variant="filled"
                                                                color="white"
                                                                size="sm"
                                                                className="my-2 rounded-full py-1 px-4 font-body font-thin text-sm 
                                                                normal-case hover:border-1 hover:border-blue-500 
                                                                hover:text-blue-500 border border-gray-700 mr-4">
                                                                Upgrade Plan
                                                            </Button>
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className='min-h-[100px] mt-4 max-w-40'>
                            <Card className="rounded-xl" >
                                <CardHeader floated={false} className="w-[120px] h-[120px] mx-auto rounded-full">
                                    <Image alt="Profile PictureORGANISATION —" 
                                        key={timestamp}
                                        src={pfpImageUrl}
                                        className=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src=`${profilepic}`;
                                        }} 
                                    />
                                </CardHeader>
                                <CardBody className="text-center">
                                    <Typography variant="h6" color="blue-gray" className="mb-2">
                                        {userData?.name || ''}
                                    </Typography>
                                </CardBody>
                                <CardFooter className="flex justify-center gap-7 pt-2 bg-blue-gray-400 rounded-b-xl">
                                    <Typography className="text-white font-body text-xs" textGradient>
                                        <MaterialIcon name="store"/> [ORGANISATION]<br /><MaterialIcon name="badge"/> [JOB ROLE]
                                    </Typography>                                    
                                </CardFooter>
                            </Card>
                        </div>
                    </div> 
                </div>
                <div className="">
                </div>
            </ContentWrapper>
        </>
    );
}
