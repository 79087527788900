import React from "react";

export default function ContentWrapper(props) {
    return (
        <div
            className="relative flex flex-col break-words min-w-screen">
            <div className="p-0 m-0">
                {props.children}
            </div>
        </div>
    );
}
