import {useField} from "formik";
import {Radio, Alert} from "@material-tailwind/react";

export default function FormikRadio({...props}) {
    const [field, meta] = useField(props);

    return(
        <>
            <Radio {...field} {...props} error={meta.touched && meta.error ? true : undefined}/>
            {(meta.touched && meta.error && 
                <Alert color="red" className="mt-1 rounded-lg py-1 pl-5">{meta.error}</Alert>
            )}
        </>
    )
}
