// Empty Data Structures

export const emptySurveyAnswerData = {
    preset: [{text:'', nanoid:''}],
    custom: [{text:'', nanoid:''}],
}

export const emptyCustomAnswerData = [
    {text:'', nanoid:'', saved: false},
]

export const emptyPresetAnswerData = [
    {text:'', nanoid:'', saved: false},
]

export const emptySurveyQuestionResponse = {
    text: '',
    position: 1,
};

export const emptySurveyQuestionData = {
    answer_type: 'multiple',
    description: '',
    is_allow_custom: false,
    is_random_order: false,
    is_view_all: true,
    position: 1,
    question_type: 'mixed',
    text: '',
    preset_answers: [],
    is_demographic_question: false,
    answer_limit: 150
};
