import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiFetch} from "../utils/api";
/**
 * @typedef {import('@reduxjs/toolkit').AsyncThunk} AsyncThunk
 */

/**
 * @type AsyncThunk<*, {email: string, password: string}, {}>
 */
export const login = createAsyncThunk(
    'auth/login',
    async (authData) => {
        const response = await apiFetch('/v2/auth/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(authData),
        }).then(r => {
            if (r.status >= 400)
                throw new Error('Auth Error');
            return r;
        });
        return response.json();
    }
);

export const totpVerify = createAsyncThunk(
    'auth/totp',
    async (code) => {
        const response = await apiFetch('/v2/auth/totp', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(code),
        }).then(r => {
            if (r.status >= 400)
                throw new Error('Auth Error');
            if (r.status !== 204)
                throw new Error('Error with provided code');
            return r;
        });
        return response;
    }
);

export const totpSetup = createAsyncThunk(
    'auth/setup-totp',
    async (setupData) => {
        const response = await apiFetch('/v2/auth/setup-totp', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(setupData),
        }).then(r => {
            if (r.status >= 400)
                throw new Error('Auth Error');
            if (r.status !== 204)
                throw new Error('Error with provided code');
            return r;
        });
        return response;
    }
);

export const fetchSession = createAsyncThunk(
    'auth/fetchSession',
    async () => {
        const response = await apiFetch('/v2/auth/session')
            .then(r => {
                if (r.status >= 400)
                    throw new Error('Auth Error');
                return r;
            });
        return response.json();
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        const response = await apiFetch('/v2/auth/logout');
        return response.data;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        // unknown pending totpPending loggedOut loggedIn
        status: 'unknown',
        error: false,
        errorMessage: '',
        session: {},
    },
    extraReducers(builder) {
        // Login Async States
        builder.addCase(login.pending, (state) => {
            state.status = 'pending';
            state.error = false;
        });
        builder.addCase(login.fulfilled, (state) => {
            state.status = 'totpPending';
            state.errorMessage = '';
            state.error = false;
        });
        builder.addCase(login.rejected, (state) => {
            state.status = 'loggedOut';
            state.errorMessage = 'Oops! Something has gone wrong. Please check and try again.';
            state.error = true;
        });

        // TOTP Async States
        builder.addCase(totpVerify.pending, (state) => {
            state.status = 'totpVerifyPending';
            state.error = false;
        });
        builder.addCase(totpVerify.fulfilled, (state) => {
            state.status = 'loggedIn';
            state.errorMessage = '';
            state.error = false;
            // Dont need to fetch session, as that is done after the redirect
        });
        builder.addCase(totpVerify.rejected, (state) => {
            state.status = 'loggedOut';
            state.errorMessage = 'Oops! Something has gone wrong. Please check and try again.';
            state.error = true;
        });

        builder.addCase(totpSetup.pending, (state) => {
            state.status = 'totpSetupPending';
            state.error = false;
        });
        builder.addCase(totpSetup.fulfilled, (state) => {
            state.status = 'loggedIn';
            state.errorMessage = '';
            state.error = false;
            // Dont need to fetch session, as that is done after the redirect
        });
        builder.addCase(totpSetup.rejected, (state) => {
            state.status = 'loggedOut';
            state.errorMessage = 'Oops! Something has gone wrong. Please check and try again.';
            state.error = true;
        });

        // Session Async States
        builder.addCase(fetchSession.pending, (state) => {
            state.status = 'pending';
        })
        builder.addCase(fetchSession.fulfilled, (state, action) => {
            state.session = action.payload;
            console.log('fetchSession.fulfilled');
        });
        builder.addCase(fetchSession.rejected, (state) => {
            state.status = 'loggedOut';
        });

        // Logout Async States
        builder.addCase(logout.fulfilled, (state) => {
            state.status = 'loggedOut';
            state.errorMessage = '';
            state.error = false;
        });
    }
});

export const authSelector = (state) => state[authSlice.name];
export default authSlice.reducer;
