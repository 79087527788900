import React from 'react';
import PropTypes from 'prop-types';

const colors = {
    white: 'text-white',
    blueGray: 'text-blue-gray-500',
    gray: 'text-gray-800',
    brown: 'text-brown-500',
    deepOrange: 'text-deep-orange-500',
    orange: 'text-orange-500',
    amber: 'text-amber-500',
    yellow: 'text-yellow-600',
    lime: 'text-lime-500',
    lightGreen: 'text-light-green-500',
    green: 'text-green-500',
    teal: 'text-teal-500',
    cyan: 'text-cyan-500',
    "light-blue": 'text-light-blue-500',
    blue: 'text-blue-400',
    indigo: 'text-indigo-500',
    deepPurple: 'text-deep-purple-500',
    purple: 'text-purple-500',
    pink: 'text-pink-500',
    red: 'text-red-500',
};

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
    '7xl': 'text-7xl',
    '8xl': 'text-8xl',
    '9xl': 'text-9xl',
};
export default function MaterialIcon({ family, name, color, size, ...rest }) {
    let iconUI;

    iconUI = (
        <span
            {...rest}
            className={`material-icons ${colors[color]} ${
                size ? sizes[size] : 'text-base'
            }`}
        >
            {name}
        </span>
    );

    return iconUI;
}

MaterialIcon.defaultProps = {
    size: 'base',
};

MaterialIcon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
};
