import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import { fetcherFull } from 'utils/api';
import FormikInput from '../form/formik-input';
import { toast } from 'react-toastify';
import FormikCheckbox from '../form/formik-checkbox';

export default function ProcessReportDialog({showProcessDialog, setShowProcessDialog, processingReport, surveyAbuseMutate,
    surveyRespondentAnswersMutate}) {
    
    const reportSubmit = async (values) => {
        const reportToSend = processingReport;
        reportToSend.admin_comment = values.admin_comment || '';
        reportToSend.status = (values.accept_report ? 'accepted' : reportToSend.status);
        reportToSend.status = (values.reject_report ? 'rejected' : reportToSend.status);
        const response = await fetcherFull(
            `/v2/response/question/${reportToSend.question_nanoid}/answer/${reportToSend.respondent_answer_nanoid}/abuse/${reportToSend.id}`,
            'PUT',
            reportToSend
        );
        if (response && response?.id) {
            toast.success('Report Processed');
            surveyAbuseMutate();
            surveyRespondentAnswersMutate();
        } else {
            toast.error('Error Processing Report');
        }
        setShowProcessDialog(showProcessDialog=>!showProcessDialog)
    };

    return (
        <>
            <Dialog open={showProcessDialog} handler={() => setShowProcessDialog(showProcessDialog=>!showProcessDialog)}>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg min-w-fit">
                    Moderate Report
                </DialogHeader>
                <Formik initialValues={{
                    admin_comment: processingReport.admin_comment || '',
                    accept_report: (processingReport.status === 'accepted' ? true : false) || false,
                    reject_report: (processingReport.status === 'rejected' ? true : false) || false
                    }} onSubmit={reportSubmit}>
                    {({values, setFieldValue}) => (

                        <Form>
                        <DialogBody divider className="overflow-y-auto max-h-[80vh] mb-7 font-body">
                            <Typography className='font-body pb-4'>Accepting a Report will hide the reported answer from respondents.</Typography>
                            <Typography as="span" className='font-body pb-8'><FormikInput className="font-body" type="text" variant="static" size="md" 
                                color="indigo" name="admin_comment" placeholder="Enter an admin comment" /></Typography>
                            <Typography as="div" className='font-body text-center'>
                                <FormikCheckbox name="accept_report" label="Accept Report" className=""
                                    onChange={() => {
                                        setFieldValue('accept_report', true);
                                        if(values.reject_report) setFieldValue('reject_report', false);
                                    }}
                                    type="checkbox"/>
                                <FormikCheckbox name="reject_report" label="Reject Report" className=""
                                    onChange={() => {
                                        setFieldValue('reject_report', true);
                                        if(values.accept_report) setFieldValue('accept_report', false);
                                    }}
                                    type="checkbox" />
                            </Typography>
                        </DialogBody>
                        <DialogFooter className="">
                            <div className="absolute bottom-4 right-2">
                                <Button type="submit"
                                        color="white"
                                        className="
                                        mr-4
                                        rounded-full 
                                        text-lg 
                                        px-6 py-1
                                        hover:border-1
                                        hover:border-blue-500
                                        hover:text-blue-500
                                        border
                                        border-gray-500
                                        font-body normal-case font-thin"
                                        variant="filled">
                                        Save Changes
                                </Button>
                                <Button type="button"
                                        color="white"
                                        className="
                                        mr-4
                                        rounded-full 
                                        text-lg 
                                        px-6 py-1
                                        hover:border-1
                                        hover:border-blue-500
                                        hover:text-blue-500
                                        border
                                        border-gray-500
                                        font-body normal-case font-thin"
                                        variant="filled"
                                        onClick={() => setShowProcessDialog(showProcessDialog=>!showProcessDialog)}>
                                    Close Dialogue
                                </Button>
                            </div>
                        </DialogFooter>
                    </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
}

