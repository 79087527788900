import React from 'react';
import {useFormikContext} from 'formik';
import { emptySurveyQuestionResponse } from 'utils/consts';
import {IconButton, Button, Tooltip} from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import FormikTextarea from './formik-textarea';

export default function QuestionAnswerInputs({
    questionIndex, pushCallback, removeCallback, swapCallback, questionAnswersToDelete, setQuestionAnswerDeleteArray
    }) {
    const {values} = useFormikContext();

    const addResponse = () => {
        const newResponse =  Object.assign({}, emptySurveyQuestionResponse);
        const pos = values.questions[questionIndex].preset_answers.length === 0
              ? 1 :  values.questions[questionIndex].preset_answers.length + 1;
        newResponse.position = pos;
        pushCallback(newResponse);
    };

    const moveResponseUp = (swap, index) => {
        const oldPos = values.questions[questionIndex].preset_answers[index].position;
        values.questions[questionIndex].preset_answers[index].position = values.questions[questionIndex].preset_answers[index-1].position;
        values.questions[questionIndex].preset_answers[index-1].position = oldPos;
        swap(index, index-1);
    };

    const moveResponseDown = (swap, index) => {
        const oldPos = values.questions[questionIndex].preset_answers[index].position;
        values.questions[questionIndex].preset_answers[index].position = values.questions[questionIndex].preset_answers[index+1].position;
        values.questions[questionIndex].preset_answers[index+1].position = oldPos;
        swap(index, index+1);
    };

    return (
        <>
            <div className="flex flex-col gap-2">
                {values.questions[questionIndex].preset_answers?.sort((a, b) => {return a.position - b.position;}).map((answer, index) => (
                    <div className="flex" key={index}>
                        <FormikTextarea color="blue" shrink={true} variant="static" rows="1" className="min-h-0 focus:border-0 font-body"
                            autoComplete="off" name={`questions.${questionIndex}.preset_answers.${index}.text`}/>
                        <IconButton color="blue-gray"
                                    type="button"
                                    variant="text"
                                    disabled={index === 0}
                                    onClick={() => moveResponseUp(swapCallback, index)}>
                                <MaterialIcon name="arrow_upward" size="xl" />
                        </IconButton>
                        <IconButton color="blue-gray"
                                    type="button"
                                    variant="text"
                                    disabled={index === values.questions[questionIndex].preset_answers?.length-1}
                                    onClick={() => moveResponseDown(swapCallback, index)}>
                            <MaterialIcon name="arrow_downward" size="xl" />
                        </IconButton>
                        <IconButton color="deep-orange" type="button" 
                            variant="text"
                            onClick={() => removeCallback(index)}>
                                <MaterialIcon name="delete" size="xl" />
                        </IconButton>
                        {( index === 0 &&
                            <div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {((values.questions?.[questionIndex]?.question_type !== 'freeform') &&
            <div className="flex justify-center mt-4 mb-2">
                <div>
                    <Tooltip className="font-body" 
                        animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 1, y: 25 }}}
                        content="Use this to add another fixed response to the question.">
                    <Button size="lg"  
                        variant="gradient"
                        color="white"
                        className="  
                        rounded-full 
                        px-4 
                        py-1
                        border
                        border-gray-500
                        hover:border-1 hover:border-blue-500 hover:text-blue-500
                        font-body 
                        font-thin 
                        normal-case"
                        type="button"                        
                        onClick={addResponse}>
                        + Add A Response
                    </Button>
                    </Tooltip>
                </div>
            </div>
            )}
        </>
    )
}
