import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import { fetcherFull } from 'utils/api';
import FormikInput from '../form/formik-input';
import { toast } from 'react-toastify';

export default function ConfirmReportDialog(props) {
    const handle = () => {
        props.setShowConfirmReportDialog(!props.showDialog);
        if (!props.showCustomResultsDialog) {
            props.setShowCustomResultsDialog(true);
        }
    }

    const reportSubmit = async (values) => {
        const response = await fetcherFull(
            `/v2/response/question/${props.question.nanoid}/answer/${props.reportedAnswer}/abuse`,
            'POST',
            {user_comment: values.user_comment}
        );
        if (response && response?.status === 'reported') {
            toast.success('Answer Reported');
        } else {
            toast.error('Error Reporting Answer');
        }
        props.setShowConfirmReportDialog(false);
        handle();
    };

    return (
        <>
            <Dialog open={props.showDialog} handler={handle} className='min-w-fit'>
                <Formik initialValues={{user_comment: ''}} onSubmit={reportSubmit}>
                    <Form>
                <DialogHeader className="bg-gray-300 text-black font-display rounded-t-lg">
                    Report an Answer
                </DialogHeader>
                <DialogBody className="overflow-y-auto max-h-[80vh] font-body">
                    <Typography className='font-body my-2 text-center'>Would you like to add a comment as to why you are reporting this answer? Please note that we take all reports seriously 
                    and will check all abuse. Any reports submitted will be treated in the strictest confidence.
                    </Typography>
                            <FormikInput className="p-2 font-body" type="text" variant="static" size="md" 
                            color="indigo" name="user_comment" placeholder="Enter your comment here!" />
                </DialogBody>
                <DialogFooter className="py-8">
                    <div className="absolute bottom-4 right-4">
                        <Button type="submit"
                                color="white"
                                className="
                                mr-4
                                rounded-full 
                                text-lg 
                                px-6 py-1
                                hover:bg-black
                                border
                                border-gray-500
                                hover:text-white
                                font-body normal-case font-thin"
                                variant="filled">
                            Report
                        </Button>
                        <Button type="button" 
                                color="white"
                                className="
                                mr-4
                                rounded-full 
                                text-lg 
                                px-6 py-1
                                hover:bg-black
                                border
                                border-gray-500
                                hover:text-white
                                font-body normal-case font-thin"
                                variant="filled"
                                onClick={()=>handle()}>
                            Close
                        </Button>
                    </div>
                </DialogFooter>
                    </Form>
                </Formik>
            </Dialog>
        </>
    );
}

