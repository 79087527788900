import { saveAs } from "file-saver";

export function apiFetch(path, params) {
    const fullUrl = process.env.REACT_APP_API_SERVER + path;
    if (params === undefined)
        params = {};
    params.credentials = 'include';
    return fetch(fullUrl, params);
}

export function fetcherSimple(path, method) {
    if (!path) {
        return;
    }
    return apiFetch(path, {method: method}).then(r => {
        if (!r.ok) {
            return r.json().then(errorJson => {
                const error = errorJson?.error || '';
                const errorMsg = errorJson?.message || '';
                throw new Error(`${error || 'Unknown Error Code'} ${errorMsg || ''}`)  
            });
        }
        return r.json();
    }).catch(err => {
        console.log(err);
        throw err;
    });
}

export function fetcherFull(path, method, params) {
    return apiFetch(path,
        {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(params),
        }).then(r => {
            if (!r.ok) {
                return r.json().then(errorJson => {
                    const error = errorJson?.error || '';
                    const errorMsg = errorJson?.message || '';
                    throw new Error(`${error || 'Unknown Error Code'} ${errorMsg || ''}`) 
                });
            }
            return r.json();
        }).catch(err => {
            console.log(err);
            throw err;
        });
    }

export function fetcherFile(path, method, filename) {
    if (!path) {
        return;
    }
    return apiFetch(path, {method: method}).then(r => {
        if (!r.ok) {
            throw new Error('Error with fetching'); 
        }
        r.blob().then((blob) => {
            saveAs(blob, filename)
        });
        return r;
    }).catch(err => {
        console.log(err);
    });
}

export function fetcherFileUpload(path, method, uploadString, uploadFile) {
    const formData = new FormData();
    formData.append(uploadString, uploadFile);
    return apiFetch(path,
        {
            method: method,
            body: formData,
        }).then(r => {
            if (!r.ok) {
                console.log(r)
                const contentType = r.clone().headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    throw r.json().then(errorJson => {
                        const error = errorJson?.error || '';
                        const errorMsg = errorJson?.message || '';
                        throw new Error(`${error || 'Unknown Error Code'} ${errorMsg || ''}`) 
                    });
                } else {
                        const error = r?.statusText || '';
                        throw new Error(`${error || 'Unknown Error Code'}`)
                }
            }
            return r;
        }).catch(err => {
            console.log(err);
            throw err;
        });
}
