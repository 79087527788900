import {useField} from "formik";
import {Input, Alert} from "@material-tailwind/react";

export default function FormikInput({...props}) {
    const [field, meta] = useField(props);

    return(
        <>
            <Input {...field} {...props} value={meta.value || ''} error={meta.touched && meta.error ? true : undefined}/>
            {(meta.touched && meta.error && 
                <Alert 
                color="red" className="mt-1 rounded-lg py-2 px-1 text-sm">{meta.error}</Alert>
            )}
        </>
    )
}
